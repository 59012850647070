/* global PDFJS_WORKER_NAME */

import React from 'react';

import { Worker } from '@react-pdf-viewer/core';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { Provider } from 'jotai';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Location } from 'react-router-dom';

import BackboneRouter from '@src/components/utils_v2/backbone_router';

import QueryCacheClear from './utils_v2/query_cache_clear/query_cach_clear';

interface IRootProps {
  children: React.ReactNode,
  location?: Location,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry:                false,
      staleTime:            5 * 60 * 1000, // Data stays fresh for 5 minutes
    },
  },
});

/**
 * Use this component as root for all entry points.
 * It setup environment for react components
 */
const Root = ({ children, location }: IRootProps): JSX.Element => {
  return (
    <QueryClientProvider client={ queryClient }>
      <RollbarProvider instance={ window.rollbar }>
        <Provider>
          <BackboneRouter location={ location }>
            <React.StrictMode>
              <QueryCacheClear />
              <Worker workerUrl={ `/${PDFJS_WORKER_NAME}` }>
                <ErrorBoundary>
                  { children }
                </ErrorBoundary>
              </Worker>
            </React.StrictMode>
          </BackboneRouter>
        </Provider>
      </RollbarProvider>
    </QueryClientProvider>
  );
};

export default Root;
