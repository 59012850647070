import Rollbar from 'rollbar';

import versionJSON from '@config/version.json';

const getRollbarConfig = (rollbarAccessToken: string, env: string) => {
  return {
    enabled:                    env !== 'development',
    accessToken:                rollbarAccessToken,
    captureUncaught:            true,
    captureUnhandledRejections: true,
    environment:                env,
    payload:                    {
      environment: env,
      client:      {
        javascript: {
          source_map_enabled:    true,
          code_version:          versionJSON.version,
          guess_uncaught_frames: true,
        },
      },
    },
    server: {
      root: 'webpack://docyt-frontend/./',
    },
  };
};

const loadRollbar = (rollbarAccessToken: string, env: string) => {
  return new Rollbar(getRollbarConfig(rollbarAccessToken, env));
};

export { loadRollbar, getRollbarConfig };
