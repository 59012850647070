import React from 'react';

import { duplicateVendorsPath } from '@src/routes';
import { TID } from '@src/types/common';

import { DisconnectedBankAccountIcon, DisconnectedGeneralLedgerIcon, DisconnectedRevenueIntegrationIcon,
  DuplicateVendorIcon, LedgerConnectionIssueIcon } from '@src/components/utils/icomoon';
import CoaSyncErrorIcon from '@src/components/utils/icomoon/od_coa_sync_errors';

import { TBusinessIntent, IFilterPreference, IIconConfig } from '../types';

export const isDisabledForFirmClosingSuite = ():boolean => {
  if (window.Docyt.currentAdvisor.isBookgptAccountantUser()) {
    return window.Docyt.currentAdvisor.getBusinessIntent() === window.configData.business_intent.FIRM_CLOSING_SUITE;
  }
  return false;
};

const tooltipMessage:string = 'Upgrade to Premium for advanced dashboard features.';

// Have an optional parameter if the view part is need to be catered in future scope
export const filterMapper = (view?: string): IFilterPreference[] => [
  {
    heading:    'Issues',
    headingKey: 'issues',
    status:     null,
    child:      [
      {
        heading:    '',
        headingKey: '',
        status:     null,
        elements:   [
          {
            title:       'Disconnected General Ledger',
            name:        'disconnectedLedger',
            description: 'Indicates that the General Ledger (GL) connection for the business is not active or has been disrupted.',
            disabled:    false,
            status:      null,
          },
          {
            name:        'disconnectedRevenue',
            title:       'Disconnected Revenue Integrations',
            description: 'Highlights that the revenue integrations (e.g., Stripe, Square) are not connected or have been disconnected.',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            name:        'disconnectedBank',
            title:       'Disconnected Bank Accounts',
            description: 'Shows that one or more bank accounts linked to the business are no longer connected.',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            name:        'ledgerIssues',
            title:       'General Ledger Connection Issues',
            description: 'Flags errors or issues encountered while trying to connect or sync with the General Ledger.',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            name:        'duplicateVendor',
            title:       'Duplicate Vendors',
            description: 'Identifies multiple instances of the same vendor in the system, which may require cleanup or merging.',
            disabled:    false,
            tooltipText: '',
            status:      null,
          },
          {
            name:        'chartOfAccountSyncErrors',
            title:       'COA Sync Errors',
            description: ' Indicates synchronization issues between the Chart of Accounts (COA) in the system and the General Ledger.',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: '',
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Expense',
    headingKey: 'expense',
    status:     null,
    child:      [
      {
        heading:    'Bill Pay',
        headingKey: 'billPay',
        status:     null,
        elements:   [
          {
            title: 'Unverified Invoices',
            description:
                'Manually added invoices to the system for tracking and processing.',
            name:        'uploadedInvoices',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Unapproved Invoices',
            description: 'Invoices pending approval for processing.',
            name:        'approvedInvoices',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title: 'Ready to Pay Invoices',
            description:
                'Approved invoices prepared for payment processing.',
            name:        'readyToPayInvoices',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
      {
        heading:    'Corporate Cards',
        headingKey: 'corporateCards',
        status:     null,
        elements:   [
          {
            title: 'Unapproved Receipts',
            description:
                'Receipts pending review or confirmation.',
            name:        'unapprovedReceipts',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
      {
        heading:    'Expense Reports',
        headingKey: 'expenseReports',
        status:     null,
        elements:   [
          {
            title:       'Employee Reimbursements',
            description: 'Payments made to employees for business expenses they personally covered.',
            name:        'employeeReimbursements',
            disabled:    true,
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'My Reimbursements',
            description: 'Shows all expenses you\'ve submitted for repayment.',
            name:        'myReimbursements',
            disabled:    true,
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Revenue',
    headingKey: 'revenue',
    status:     null,
    child:      [
      {
        heading:    'Undeposited Revenue',
        headingKey: 'undepositedRevenue',
        status:     null,
        elements:   [
          {
            title:       'Undeposited Revenue Amount',
            description: 'Payments received but not yet deposited into a bank account.',
            name:        'undepositedRevenueAmount',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
            status:      null,
          },
        ],
      },
      {
        heading:    'Pending Review',
        headingKey: 'pendingReview',
        status:     null,
        elements:   [
          {
            title: 'Missing Revenue Reports',
            description:
                'Count of the number of days that revenue hasn\'t arrived for.',
            name:     'missingRevenueReports',
            disabled: !(view === 'accountant' || view === 'businessOwner'),
            status:   null,
          },
          {
            title:       'Unbalanced Reports',
            description: 'Financial records where debits and credits do not match, indicating discrepancies.',
            name:        'unbalancedReports',
            disabled:    !(view === 'accountant' || view === 'businessOwner'),
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Continuous Reconciliation',
    headingKey: 'continuousReconciliation',
    status:     null,
    child:      [
      {
        heading:    '',
        headingKey: '',
        status:     null,
        elements:   [
          {
            title:       'Uncategorized Transactions',
            description: 'Transactions that haven\'t been assigned a specific category yet.',
            name:        'uncategorizedTransactions',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Uncleared Documents',
            description: 'Records awaiting review or approval to ensure accuracy and completeness.',
            name:        'unclearedDocuments',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Month End Closing',
    headingKey: 'monthEndClosing',
    status:     null,
    child:      [
      {
        heading:    '',
        headingKey: '',
        status:     null,
        elements:   [
          {
            title:       'Missing Bank Statements',
            description: 'Periods where no bank transactions or statements are available for review.',
            name:        'missingBankStatements',
            disabled:    true,
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Audit Scan',
            description: 'Audit Scan ensures all financial data is accurate, consistent, and compliant by thoroughly reviewing transactions.',
            name:        'auditScan',
            disabled:    false,
            status:      null,
          },
          {
            title:       'Last Close Date',
            description: 'Last Close Date marks the final date when the previous month\'s books were closed.',
            name:        'lastCloseDate',
            disabled:    false,
            status:      null,
          },
          {
            title:       'Notes',
            description: 'Log notes when closing books and downloading PnL and Balance Sheet for closed months.',
            name:        'notes',
            disabled:    false,
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Collaboration',
    headingKey: 'collaboration',
    status:     null,
    child:      [
      {
        heading:    'Flagged Items',
        headingKey: 'flaggedItems',
        status:     null,
        elements:   [
          {
            title:       'Flagged Invoices',
            description: 'Invoices marked for review due to potential issues or discrepancies.',
            name:        'flaggedInvoices',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Flagged Receipts',
            description: 'Receipts marked for review due to potential errors or missing information.',
            name:        'flaggedReceipts',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Flagged Transactions',
            description: 'Transactions marked for review due to discrepancies or missing information.',
            name:        'flaggedTransactions',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
      {
        heading:    'Document Requests',
        headingKey: 'documentRequests',
        status:     null,
        elements:   [
          {
            title:       'Reviewed',
            description: 'Verified document requests for accuracy and completeness.',
            name:        'reviewed',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
          {
            title:       'Open',
            description: 'Pending actions requiring documents to complete financial processes.',
            name:        'open',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
      {
        heading:    'Transaction Messages',
        headingKey: 'transactionMessages',
        status:     null,
        elements:   [
          {
            title:       'Unread Transaction Messages',
            description: 'Shows the number of unread messages related to transaction and document discussions for your business.',
            name:        'unreadTransactionMessages',
            disabled:    false,
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Business Chat Rooms',
    headingKey: 'businessChat',
    status:     null,
    child:      [
      {
        heading:    '',
        headingKey: '',
        status:     null,
        elements:   [
          {
            title:       'Unread Business Messages',
            description: 'Shows the number of unread messages related to business communication.',
            name:        'unreadBusinessChatsCounts',
            disabled:    false,
            status:      null,
          },
        ],
      },
    ],
  },
  {
    heading:    'Mailroom',
    headingKey: 'mailroom',
    status:     null,
    child:      [
      {
        heading:    '',
        headingKey: '',
        status:     null,
        elements:   [
          {
            title:       'Unreviewed Documents',
            description: 'Items in the Mailroom that haven’t been reviewed or processed yet.',
            name:        'unreviewedDocuments',
            disabled:    isDisabledForFirmClosingSuite(),
            tooltipText: tooltipMessage,
            status:      null,
          },
        ],
      },
    ],
  },
];

export const expenseKeyToStringMap = {
  billPay:        'Bill Pay',
  corporateCards: 'Corporate Cards',
  expenseReports: 'Expense Reports',
};

export const revenueKeyToStringMap = {
  undepositedRevenue: 'Undeposited Revenue',
  pendingReview:      'Pending Review',
};

export const reconciliationKeyToStringMap = {
  uncategorizedTransactions: 'Uncategorized Transactions',
  unclearedDocuments:        'Uncleared Documents',
};

export const monthEndCloseKeyToStringMap = {
  missingBankStatements: 'Missing Bank Statements',
  auditScan:             'Audit Scan',
  lastCloseDate:         'Last Close Date',
  notes:                 'Notes',
};

export const collaborationKeyToStringMap = {
  flaggedItems:        'Flagged Items',
  documentRequests:    'Document Requests',
  transactionMessages: 'Unread Transaction Messages',
};

export const billPayColors: ColorMapping = {
  uploadedInvoices:   '#8a2be2',
  approvedInvoices:   '#00bfff',
  readyToPayInvoices: '#20b2aa',
};

interface ColorMapping {
  [key: string]: string;
}

export const flaggedItemsColors: ColorMapping = {
  flaggedInvoices:     '#8a2be2',
  flaggedReceipts:     '#00bfff',
  flaggedTransactions: '#20b2aa',
};

export const documentRequestsColors: ColorMapping = {
  open:     '#FFB648',
  reviewed: '#00B887',
};

export const pieChartConstants = {
  startAngle:  90,
  endAngle:    452,
  innerRadius: 12,
  outerRadius: 15,
};

export const pieChartColors = ['#0088FE', '#00C49F'];
export const availableViews = {
  businessOwnerView: 'business',
  accountant:        'accountant',
};
export const iconSize = 16;

export const DASHBOARD_ICONS: IIconConfig[] = [
  {
    key:       'disconnectedLedger',
    icon:      <DisconnectedGeneralLedgerIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected General Ledger',
  },
  {
    key:       'disconnectedRevenue',
    icon:      <DisconnectedRevenueIntegrationIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected Revenue Integrations',
  },
  {
    key:       'disconnectedBank',
    icon:      <DisconnectedBankAccountIcon fontSize={ iconSize } />,
    hoverText: 'Disconnected Bank Accounts',
  },
  {
    key:       'ledgerIssues',
    icon:      <LedgerConnectionIssueIcon fontSize={ iconSize } />,
    hoverText: 'General Ledger Sync Issues',
  },
  {
    key:       'duplicateVendor',
    icon:      <DuplicateVendorIcon fontSize={ iconSize } />,
    hoverText: 'Duplicate Vendors',
  },
  {
    key:       'chartOfAccountSyncErrors',
    icon:      <CoaSyncErrorIcon fontSize={ iconSize } />,
    hoverText: 'COA Sync Errors',
  },
];

export const iconSizeTooltip = 13;
export const duplicateVendorIconSize = 15;
const defaultUrl = '/operational_dashboard';
export const GeneralLedgerDisconnectedTooltipData = {
  id:          '2002',
  heading:     'General Ledger Disconnected',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/setup-business/${businessId}?step=2`
      : defaultUrl;
  },
  key:       'generalLedgerDisconnected',
  titleIcon: <DisconnectedGeneralLedgerIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};

const isBusinessIntent = (intent: TBusinessIntent | undefined, expectedIntent: string): boolean => {
  return intent === expectedIntent;
};

export const DuplicateVendorsTooltipData = {
  id:          '2003',
  heading:     'Duplicate Vendors',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID, businessIntent?: TBusinessIntent): string => {
    if (isBusinessIntent(businessIntent, 'BooksGPT')) {
      return duplicateVendorsPath(businessId as TID);
    }
    if (businessId) {
      return `/businesses/${businessId}/vendor_service?merge_available=true`;
    }
    return defaultUrl;
  },
  key:       'duplicateVendors',
  titleIcon: <DuplicateVendorIcon fontSize={ duplicateVendorIconSize } inColor="red-400" />,
};

export const GeneralLedgerConnectionIssuesTooltipData = {
  id:          '2004',
  heading:     'General Ledger Sync Issues',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre`
      : defaultUrl;
  },
  key:       'quickBookSyncIssue',
  titleIcon: <LedgerConnectionIssueIcon fontSize={ iconSizeTooltip } inColor="orange-500" />,
};

export const RevenueIntegrationsTooltipData = {
  id:          '2005',
  heading:     'Revenue Integrations Disconnected',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID): string => {
    return businessId
      ? `/settings/integrations/${businessId}/external_systems`
      : defaultUrl;
  },
  key:       'revenueIntegrations',
  titleIcon: <DisconnectedRevenueIntegrationIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};

export const DisconnectedBankAccountsTooltipData = {
  id:          '2006',
  heading:     'Bank Accounts Disconnected',
  actionLabel: 'Review',
  actionLink:  () => '/financial_institution_connection_center ',
  key:         'disconnectedBankAccounts',
  titleIcon:   <DisconnectedBankAccountIcon fontSize={ iconSizeTooltip } inColor="red-400" />,
};

export const CoaSyncErrorsData = {
  id:          '2007',
  heading:     'COA Sync Errors',
  actionLabel: 'Review',
  actionLink:  (businessId?: TID, businessIntent?: TBusinessIntent): string => {
    if (isBusinessIntent(businessIntent, 'BooksGPT')) {
      return `/chats?channelUrl=DYTBID_${businessId}`;
    }
    if (businessId) {
      return `/businesses/${businessId}/reconciliation_center/accounts?type=categories`;
    }
    return defaultUrl;
  },
  key:       'disconnectedBankAccounts',
  titleIcon: <CoaSyncErrorIcon fontSize={ iconSizeTooltip } inColor="orange-500" />,
};

export const loaderTooltipTextBasedOnStatus:Record<string, React.ReactNode> = {
  in_ledger_setup: (
    <>
      AI learning is in progress...
      <br />
      Please wait until it&apos;s complete.
    </>
  ),
  in_progress: (
    <>
      Audit scan is in progress...
      <br />
      Please wait until it&apos;s complete.
    </>
  ),
  pending: (
    <>
      Audit scan is in progress...
      <br />
      Please wait until it&apos;s complete.
    </>
  ),
  completed: (
    <>
      Changes detected in the general ledger.
      <br />
      Re-run the audit to view the updated data.
    </>
  ),
  closing_books: (
    <>
      Marking books as closed...
      <br />
      Please wait until it&apos;s complete.
    </>
  ),
};
